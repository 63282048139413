import "./App.css";
import PikasoCanvas from "./Components/Canvas/PikasoCanvas";


const App = () => {
  
  return <>
    <PikasoCanvas />
  </>
}
export default App;
