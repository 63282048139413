import usePikaso from "pikaso-react-hook";



import LOGO from "../../Asset 27.png";
import { useState } from "react";
import { eventWrapper } from "@testing-library/user-event/dist/utils";

const PikasoCanvas = () => {
  // SETTINGS FOR CANVAS
  const [ref, editor] = usePikaso({
    selection: {
      transformer: {
        borderStroke: "#343a40",
        borderStrokeWidth: 2,
        anchorSize: 10,
        anchorColor: "#343a40",
        anchorStroke: "#343a40",
        anchorBorderWidth: 1,
        anchorCornerRadius: 30
      }
    },
    snapToGrid: {
      strokeWidth: 1,
      stroke: "purple",
      dash: [2, 2]
    }
  });


  // SELECTED SHAPE ON CANVAS (ONE OR MORE)
  const [shapeSelected, setShapeSelected] = useState(null);

  // CUSTOMIZATION PANELS SHOW/HIDE
  const [fontPanel, setFontPanel] = useState(false);
  const [shapePanel, setShapePanel] = useState(false);
  const [layers, setLayers] = useState(false);

  // TEXT CUSTOMIZING ARRAYS
  let textFonts = ["serif", "sans-serif", "fantasy", "cursive", "monospace"];
  let textAlignments = ["left", "center", "right"];
  let colors = ["#ff0000", "#008000", "#0000ff", "#ffff00", "#000000", "#ffc0cb", "#800080", "#ff4500"];

  // CUSTOM BACKGROUND COLOR FOR CANVAS
  const [canvasBg, setCanvasBg] = useState('#ffffff');


  // CREATE A CIRCLE
  const createCircle = () => {
    editor.shapes.circle.insert({
      x: 70,
      y: 70,
      radius: 50,
      fill: "red",
      stroke: "black",
    });
    setLayers(false);
  }

  // CREATE A SQUARE
  const createSquare = () => {
    editor.shapes.rect.insert({
      x: 10,
      y: 10,
      height: 70,
      width: 70,
      fill: "green",
      stroke: "transparent",
      cornerRadius: 0,
      opacity: 1,
    })
    setLayers(false);
  }

  // CREATE A REACTANGLE
  const createRect = () => {
    editor.shapes.rect.insert({
      x: 10,
      y: 10,
      height: 60,
      width: 100,
      fill: "blue",
      stroke: "transparent",
      opacity: 1,
      // fillRadialGradientColorStops: [0, "red", .5, "green", 1, "blue"],
      // fillRadialGradientStartPoint: {x: 0, y: 0},
      // fillRadialGradientEndPoint: {x: 100, y: 100},
      // fillRadialGradientStartRadius: 10,
      // fillRadialGradientEndRadius: 100
      
    })
    setLayers(false);
  }

  // CREATE A TRIANGLE
  const createTriangle = () => {
    const tri = editor.shapes.triangle.insert({
      radius: 70,
      x: 70,
      y: 70,
      fill: "#ff6347",
      stroke: "transparent",
      opacity: 1,
      rotation: 90
    })
    console.log(tri)
    setLayers(false);
  }

  // ADD CUSTOM SVG
  const addSVG = () => {
    let path = prompt("Enter svg code:");
    if (path) {
      editor.shapes.svg.insert({
        // data: `M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0ZM7.519 5.057c-.886 1.418-1.772 2.838-2.542 4.265v1.12H8.85V12h1.26v-1.559h1.007V9.334H10.11V4.002H8.176c-.218.352-.438.703-.657 1.055ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z`,
        data: path,
        fill: "black",
        stroke: "trasnparent",
        strokeWidth: 0,
        width: 100,
        height: 100,
        x: 10,
        y: 10,
        opacity: 1,
      })
      setLayers(false);
    }
  }

  // CREATE TEXT LABEL
  const createTextLabel = () => {
    editor.shapes.label.insert({
      container: {
        x: 40,
        y: 40,
      },
      tag: {
        fill: "transparent",
        stroke: "transparent",
        strokeWidth: 0,
        opacity: 1,

      },
      text: {
        text: "Some Text",
        padding: 5,
        fill: "black",
        fontSize: 24,
        fontFamily: "Monospace",
        stroke: "transparent",
        strokeWidth: 0,
        shadowColor: "transparent",
        shadowBlur: 0,
        opacity: 1,
      }
    })
    setLayers(false);
  }


  // ADD IMAGE TO CANVAS
  const createImage = () => {
    editor.shapes.image.insert(LOGO, {
      x: 10,
      y: 10,
      opacity: 1,
    })
    setLayers(false);
  }

  function changeHue(image) {
    // image.filters.name = "Blur";        

    // image.style.filter = `hue-rotate(${Math.floor(Math.random() * 360)}deg)`;
    // image.style.filter = `hue-rotate(45deg)`;
  }



  // PANEL TOGGLE FUNCTION
  function panelsToggler(data) {
    setLayers(false);

    // IF MULTIPLE SHAPES SELECTED, HIDE ALL PANELS
    if (data.shapes.length >= 2) {
      setFontPanel(false);
      setShapePanel(false);
      setShapeSelected(null);
      return;
    }

    // SHOW TEXT CUSTOMIZATION PANEL
    if (data.shapes.length !== 0 && data.shapes[0].node.children) {
      setFontPanel(true);
      setShapePanel(false);
    }

    // SHOW SHAPE CUSTOMIZATION PANEL
    else if (data.shapes.length !== 0 && data.shapes[0].node) {
      setShapePanel(true);
      setFontPanel(false);
    }

    // IF NO SHAPE SELECTED, HIDE ALL PANELS
    else {
      setFontPanel(false);
      setShapePanel(false);
      setShapeSelected(null);
    }
  }

  // SHAPE SELECTION CHANGES ON CANVAS
  if (editor) {
    editor.on("selection:change", (data) => {

      panelsToggler(data);
      // console.log(data.shapes);

      // IF NOTHING IS SELECTED
      if (data.shapes.length == 0) return;

      // TEXT NODE SELECTED
      if (data.shapes[0].node.children) {
        setShapeSelected(data.shapes[0].node.children);
      }

      // SHAPE NODE IS SELECTED
      else if (data.shapes[0].node.attrs) {
        setShapeSelected(data.shapes[0].node);
      }
    })
  }


  // TEXT CUSTOMIZATION

  // CHANGE TEXT FONT STYLE
  function changeFont(item, font) {
    item[1].attrs.fontFamily = font;
    editor.board.selection.deselect();
  }

  // CHANGE TEXT ALIGNMENT
  function changeTextAlignment(item, align) {
    item[1].attrs.align = align;
    editor.board.selection.deselect();
  }

  // CHANGE TEXT COLOR
  function changeTextColor(item, color) {
    item[1].attrs.fill = color;
    editor.board.selection.deselect();
  }

  // CHANGE TEXT BACKGROUND COLOR
  function changeTextBg(item, color) {
    item[0].attrs.fill = color;
    editor.board.selection.deselect();
  }

  // CUSTOM COLOR FOR TEXT (FILL, BACKGROUND)
  function customTextColor(item, key, value) {
    if (key === "fill") item[1].setAttr(key, value);
    if (key === "bg") item[0].setAttr("fill", value);
  }

  // CHANGE FONT SIZE
  function changeFontSize(item, event) {
    item[1].attrs.fontSize = event.target.value;
    editor.board.selection.deselect();
  }

  // BOLD THE SELECTED TEXT
  function boldTheText(item, style) {
    if (item[1].attrs.fontVariant == style) {
      item[1].attrs.fontVariant = "normal";
    }
    else item[1].attrs.fontVariant = style;
    editor.board.selection.deselect();
  }

  // ITALICIZE THE TEXT
  function italicTheText(item, italic) {
    if (item[1].attrs.fontStyle == italic) {
      item[1].attrs.fontStyle = "normal";
    }
    else item[1].attrs.fontStyle = italic;
    editor.board.selection.deselect();
  }

  // UNDERLINE THE SELECTED TEXT
  function underlineTheText(item, underline) {
    if (item[1].attrs.textDecoration == underline) {
      item[1].attrs.textDecoration = "normal";
    }
    else item[1].attrs.textDecoration = underline;
    editor.board.selection.deselect();
  }

  // SHAPE CUSTOMIZATION

  // CHANGE SHAPE FILL
  function changeShapeFill(item, color) {
    item.attrs.fill = color;
    editor.board.selection.deselect();
  }

  // CHANGE SHAPE STROKE COLOR
  function changeShapeStroke(item, color) {
    item.attrs.stroke = color;
    editor.board.selection.deselect();
  }

  // CUSTOM COLOR SELECTION FOR SHAPE (FILL, STROKE)
  function customShapeColor(item, key, value) {
    item.setAttr(key, value);
  }

  // CHANGE SHAPE STROKE WIDTH
  function changeShapeStrokeWidth(item, event) {
    if (event.target.value == 0) {
      item.attrs.strokeWidth = null;
      return;
    } else {
      item.attrs.strokeWidth = parseInt(event.target.value);
      console.log(item.attrs);
    }
    editor.board.selection.deselect();

  }

  // CHANGE SHAPE OPACITY
  function changeShapeOpacity(item, event) {
    item.setAttr("opacity", event.target.value / 100);
    editor.board.selection.deselect();
  }

  // CHANGE BACKGROUND GRADIENT
  function changeBgGradient() {
    editor.board.background.fill(null);

    // RADIAL GRADIENT
    // editor.board.background.overlay.node.setAttrs({
    //   fillRadialGradientColorStops: [0, colors[Math.floor(Math.random()*7)], .2, colors[Math.floor(Math.random()*7)], .5, colors[Math.floor(Math.random()*7)]],
    //   fillRadialGradientStartPoint: {x: 250, y: 250},
    //   fillRadialGradientEndPoint: {x: 250, y: 250},
    //   fillRadialGradientStartRadius: 0,
    //   fillRadialGradientEndRadius: 500
    // });

    //lINIEAR GRADIENT
    editor.board.background.overlay.node.setAttrs({
      fillLinearGradientColorStops: [0, colors[Math.floor(Math.random()*7)], .9, colors[Math.floor(Math.random()*7)]],
      fillLinearGradientStartPoint: { x: 0, y: 0 },
      fillLinearGradientEndPoint: { x: 500, y: 500 },
    })
  }

  // ROTATE 90 DEGREE
  function rotate90deg(event) {
    const bg = editor.board.background.overlay.node; 
    console.log(bg)
  }

  // EXPORT CANVAS OBJECTS TO JSON
  const exportJSON = () => {
    const data = editor.export.toJson();
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
    const link = document.createElement("a");
    link.href = jsonString;
    // link.download = `${Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)}.json`;
    link.download = "canvas.json";
    link.click();
    console.log(data);
  }

  // EXPORT CANVAS OBJECTS TO IMAGE
  function exportIMG() {
    let data = editor.export.toImage();
    const img = document.createElement('a');
    img.href = data;
    img.download = "canvas.png";
    img.click();
  }

  // LOAD JSON FILE
  function loadJSON(event) {
    editor.reset();
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], "UTF-8");
    fileReader.onloadend = (event) => {
      editor.import.json(JSON.parse(fileReader.result));
    };
    panelsToggler(null);
    setCanvasBg("#ffffff");
    editor.board.selection.deselectAll();
  };

  // UNDO REDO
  const Undo = () => {
    editor.history.undo();
  }
  const Redo = () => {
    editor.history.redo();
  }

  // RESET CANVAS TO DEFAULT
  const resetCanvas = () => {
    editor.reset();
    setCanvasBg("#ffffff");
    setLayers(false);
    document.getElementById("jsonAnchor").value = "";
  }

  // SHOW LAYERS
  function showLayers() {
    return editor.board.activeShapes.reverse().map((shape, i) => {
      // console.log(shape);
      return (
        <>
          <div key={i} onClick={() => {
            editor.board.selection.deselectAll();
            shape.select();
          }} style={{
            height: "15px",
            width: "15px",
            background: shape.node.attrs.fill,
          }}>
            {editor.board.activeShapes.length - i}
          </div>
          <button onClick={() => layerUp(i)}>UP</button>
        </>
      )
    })
  }

  function layerUp(i) {
    console.log(i);
    let temp = editor.board.activeShapes[i];
    editor.board.activeShapes[i - 1] = editor.board.activeShapes[i];
    editor.board.activeShapes[i] = temp;

  }


  return <>
    {/* CANVAS */}
    <div ref={ref}
      style={{
        width: 500,
        height: 500,
        outline: "1px solid",
        position: "absolute",
        top: 0, left: 0, right: 0, bottom: 0,
        margin: "auto",
        scale: "1",
      }}>
    </div>

    {/* SHAPES */}
    <button onClick={() => addSVG()}>
      SVG
    </button>
    <button onClick={createCircle}>Create Circle</button>
    <button onClick={createSquare}>Create Square</button>
    <button onClick={createRect}>Create Rectangle</button>
    <button onClick={createTriangle}>Create Triangle</button>
    <button onClick={createTextLabel}>Create Text Label</button>
    <button onClick={createImage}>Create Random Image</button>
    <button onClick={() => changeBgGradient(shapeSelected)}>Change Background Gradient</button>
    {/* <button onClick={() => rotate90deg()}>Rotate 90deg</button> */}
    <div>
      <button onClick={() => exportJSON()}>Export JSON</button>
      <button onClick={() => exportIMG()}>Export IMG</button>
      <input type="file" name="" id="jsonAnchor"
        accept=".json"
        onChange={(event) => loadJSON(event)}
      />
    </div>
    <button onClick={() => resetCanvas()}>Reset</button>


    {/* UNDO REDO */}
    <div style={{
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      right: "7%",
      top: "10%"
    }}>
      <button onClick={() => Undo()}>Undo</button>
      <button onClick={() => Redo()}>Redo</button>
      <button onClick={() => setLayers(!layers)}>Layers</button>
    </div>

    <div>
      <label htmlFor="color">Canvas Background</label><br />
      <input type="color" name="color" value={canvasBg} onChange={(event) => {
        setCanvasBg(event.target.value);
        editor.board.background.fill(event.target.value);
      }} />
    </div>

    {/* FONT PANEL */}
    {fontPanel &&
      <>
        <div>
          <h5>Size</h5>
          <input type="number" defaultValue={shapeSelected[1].attrs.fontSize} min={16} max={96}
            onChange={(event) => changeFontSize(shapeSelected, event)} />
          <button style={{ fontWeight: "bold" }} onClick={() => boldTheText(shapeSelected, "bold")}>B</button>
          <button style={{ fontStyle: "italic", fontWeight: "bold" }} onClick={() => italicTheText(shapeSelected, "italic")}>I</button>
          <button style={{ textDecoration: "underline" }} onClick={() => underlineTheText(shapeSelected, "underline")}>U</button>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          left: "1%",
          top: "26%"
        }}>

          {/* FONTS */}
          <h6>Fonts</h6>
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            {textFonts.map((font, i) => {
              return (
                <button key={i}
                  onClick={() => changeFont(shapeSelected, font)}
                  style={{ fontFamily: font }}
                >{font}</button>
              )
            })}
          </div>

          {/* ALIGNMENTS */}
          <h6>Alignment</h6>
          <div style={{
            display: "flex",
            flexDirection: "column"
          }}>
            {textAlignments.map((alignment, i) => {
              return (
                <button key={i} style={{ textAlign: alignment }}
                  onClick={() => changeTextAlignment(shapeSelected, alignment)}>{alignment}</button>
              )
            })}
          </div>

          {/* COLORS */}
          <h6>Colors</h6>
          <p>Text</p>
          <div style={{
            display: "flex",
          }}>
            {colors && colors.map((color, i) => {
              return <span key={i} style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                background: color
              }}
                onClick={() => changeTextColor(shapeSelected, color)}
              />
            })}
            <input type="color" defaultValue={shapeSelected[1].attrs.fill}
              onChange={(event) => customTextColor(shapeSelected, "fill", event.target.value)} />
          </div>
          <p>Background</p>
          <div style={{ display: "flex" }}>
            {colors && colors.map((color, i) => {
              return <span key={i} style={{
                width: "20px",
                height: "20px",
                marginRight: "5px",
                background: color
              }}
                onClick={() => changeTextBg(shapeSelected, color)}
              />
            })}
            <input type="color" defaultValue={shapeSelected[0].attrs.fill}
              onChange={(event) => customTextColor(shapeSelected, "bg", event.target.value)} />
          </div>
          <h6>Effects</h6>
        </div>
      </>}

    {/* SHAPE PANEL */}
    {shapePanel &&
      <>
        <div>
          <h5>Stroke Width</h5>
          <input type="number" defaultValue={shapeSelected.attrs.strokeWidth}
            min={0} max={16}
            onChange={(event) => changeShapeStrokeWidth(shapeSelected, event)} />
        </div>
        <h5>Colors</h5>
        <p>Stroke</p>
        <div style={{
          display: "flex",
        }}>
          {colors && colors.map((color, i) => {
            return <span key={i} style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              background: color
            }}
              onClick={() => changeShapeStroke(shapeSelected, color)}
            />
          })}
          <input type="color" defaultValue={shapeSelected.attrs.stroke}
            onChange={(event) => customShapeColor(shapeSelected, "stroke", event.target.value)} />
        </div>
        <p>Fill</p>
        <div style={{
          display: "flex",
          left: "1%",
          top: "20%"
        }}>
          {colors && colors.map((color, i) => {
            return <span key={i} style={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              background: color
            }}
              onClick={() => changeShapeFill(shapeSelected, color)}
            />
          })}
          <input type="color" defaultValue={shapeSelected.attrs.fill}
            onChange={(event) => customShapeColor(shapeSelected, "fill", event.target.value)} />
        </div>
        <div style={{ marginTop: "10px" }}>
          Opacity:<br />
          <input type="number"
            min={0} max={100} defaultValue={shapeSelected.attrs.opacity * 100} step="10"
            onChange={(event) => changeShapeOpacity(shapeSelected, event)} />
        </div>
      </>}
    {/* CANVAS LAYERS */}
    {layers &&
      <><div style={{
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        top: "25%",
        right: "5%"
      }}>
        {editor.board.activeShapes.length ? showLayers() : <span>No Objects</span>}
      </div></>}
  </>
}

export default PikasoCanvas;